/* ============================
   Global CSS Reset
============================ */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  font-family: Arial, sans-serif;
  color: var(--gray-3); /* General text color */
  line-height: 1.6;
  padding-top: 70px; /* Adjusted for header height */
}

html {
  scroll-behavior: smooth;
}

/* ============================
   CSS Variables for Design Consistency (Global Color Palette)
============================ */
:root {
  --primary-color: #1e52bf;    /* Blue from logo */
  --secondary-color: #699e85;  /* Green from logo */
  --gray-1: #737373;           /* Light gray */
  --gray-2: #8c8c8c;           /* Medium gray */
  --gray-3: #595959;           /* Dark gray */
  --header-bg-color: white;    /* White background for header */
  --btn-bg-color: var(--primary-color);       /* Blue buttons */
  --btn-hover-color: var(--secondary-color);  /* Green button hover */
  --btn-text-color: white;                    /* White text on buttons */
  --footer-bg-color: var(--gray-3);           /* Dark gray for footer */
  --footer-link-color: var(--primary-color);  /* Blue links in footer */
  --footer-link-hover: underline;             /* Link hover effect */
  --shadow-light: 0px 4px 10px rgba(0, 0, 0, 0.05); /* Light shadow */
  --shadow-medium: 0 4px 8px rgba(0, 0, 0, 0.2);    /* Medium shadow */
  --shadow-hover: 0 6px 12px rgba(0, 0, 0, 0.3);    /* Hover shadow */
  --padding-large: 100px 20px;  /* Large padding */
  --padding-small: 10px 20px;   /* Small padding */
  --font-large: 3em;            /* Large font */
  --font-small: 1.2em;          /* Small font */
  --neutral-light: #f9f9f9;     /* Light neutral background color */
}

/* ============================
   General Styles (Global)
============================ */
.App {
  text-align: center;
}

a {
  text-decoration: none;
  color: var(--primary-color); /* Blue links across the site */
}

a:hover {
  color: var(--secondary-color); /* Green hover for links */
}

/* ============================
   Button Styles (Reusable)
============================ */
.btn {
  background-color: var(--btn-bg-color); /* Blue buttons */
  color: var(--btn-text-color);          /* White text */
  padding: var(--padding-small);
  border-radius: 5px;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  box-shadow: var(--shadow-medium);      /* Medium shadow */
}

.btn:hover {
  background-color: var(--btn-hover-color); /* Green background on hover */
  color: var(--btn-text-color);             /* White text */
  box-shadow: var(--shadow-hover);          /* Hover shadow */
  transform: translateY(-3px);              /* Slight lift effect */
}

.btn-primary {
  background-color: var(--btn-bg-color);
  color: var(--btn-text-color);
  padding: var(--padding-small);
  border-radius: 5px;
}

/* Centralized login button using the .btn class */
.btn.login-btn {
  border-radius: 25px; /* More rounded */
  font-size: 1.2em;    /* Slightly larger font */
  letter-spacing: 1px;
}

/* ============================
   Section Padding and Flex Layouts (General)
============================ */
.section {
  padding: 50px 20px;  /* Uniform padding for sections */
}

.section-title {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: var(--secondary-color); /* Primary color for titles */
  animation: fadeInUp 1s ease-in-out;
}

.flex-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* ============================
   Footer Styles
============================ */
.footer {
  background-color: var(--footer-bg-color); /* Dark gray background */
  color: white;
  padding: 20px 0;
  text-align: center;
}

.footer-links a {
  color: var(--footer-link-color);  /* Blue links in footer */
  margin: 0 10px;
}

.footer-links a:hover {
  text-decoration: var(--footer-link-hover); /* Underline on hover */
}

.social-media a img {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}

/* ============================
   Responsive Layouts (Global)
============================ */
@media (max-width: 768px) {
  .flex-container {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .btn {
    font-size: 1em;
    padding: 12px 25px;
  }
}