/* ============================
   HEADER STYLES
============================ */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: var(--header-bg-color);
  box-shadow: var(--shadow-light);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.logo img {
  width: 300px;
  height: auto;
  display: block;
}

.nav-links {
  display: flex;
  gap: 30px;
  align-items: center;
}

.nav-links li a {
  color: var(--primary-color);
  font-size: 1.1em;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.nav-links li a:hover {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  transform: scale(1.05);
}

.login-btn {
  background-color: #ffffff;
  color: var(--primary-color);
  padding: 12px 25px;
  border: 2px solid var(--primary-color);
  border-radius: 25px;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-btn:hover {
  background-color: var(--primary-color);
  color: #ffffff;
  box-shadow: var(--shadow-hover);
  transform: translateY(-3px);
}

/* ============================
   LANGUAGE SWITCHER STYLES
============================ */
.language-switcher {
  position: relative;
  cursor: pointer;
  margin-left: 20px;
}

.language-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--primary-color);
  cursor: pointer;
}

.language-button:hover {
  color: var(--secondary-color);
}

.language-dropdown {
  position: absolute;
  top: 35px;
  right: 0;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: var(--shadow-medium);
  width: 60px;
  overflow: hidden;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s linear 0.3s;
}

.language-switcher.open .language-dropdown {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s linear 0s;
}

.language-dropdown li {
  padding: 1px 0; /* Reduce padding to bring flags closer together */
  color: #333;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.language-dropdown li span {
  font-size: 1.5rem;
}

.language-dropdown li:hover {
  background-color: #f2f2f2;
}

/* ============================
   RESPONSIVE HEADER (MOBILE VIEW)
============================ */
@media (max-width: 768px) {
  .header {
    padding: 15px 30px;
  }

  .nav-links {
    display: none;
  }

  .logo {
    font-size: 1.5em;
  }

  .header.active .nav-links {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    top: 70px;
    left: 0;
    background-color: var(--header-bg-color);
    width: 100%;
    padding: 20px 0;
  }

  .language-switcher {
    margin-left: auto;
    margin-right: 0;
  }
}

.nav-links {
  list-style-type: none; /* Remove default bullet points */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}

.nav-links li {
  display: inline-block; /* Ensure list items are aligned horizontally */
}