/* WhoWeAreSection.css */

.who-we-are-section {
    padding: 100px 20px;
    background: linear-gradient(to bottom right, #f0f4f8, #ffffff); /* Subtle gradient background */
    text-align: center;
  }

  .who-we-are-section .section-title {
    font-size: 2.8rem;
    color: var(--secondary-color); /* Use color from your global CSS */
    margin-bottom: 30px;
    font-weight: 600;
    animation: fadeInUp 1s ease-in-out;
  }

  .who-we-are-section .description {
    font-size: 1.3rem;
    color: var(--gray-2); /* Medium gray for body text */
    margin-bottom: 50px;
    line-height: 1.7;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .core-values {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
    justify-content: center;
    align-items: start;
  }

  .value {
    background-color: white;
    padding: 40px 30px;
    border-radius: 10px;
    box-shadow: var(--shadow-light); /* Light shadow for a modern look */
    transition: transform 0.3s, box-shadow 0.3s;
  }

  .value:hover {
    transform: translateY(-10px);
    box-shadow: var(--shadow-hover); /* Add hover effect */
  }

  .value h3 {
    font-size: 1.8rem;
    color: var(--primary-color); /* Primary color for emphasis */
    margin-bottom: 15px;
  }

  .value p {
    font-size: 1.1rem;
    color: var(--gray-3);
    line-height: 1.6;
  }

  .value .icon {
    font-size: 2.5rem;
    color: var(--secondary-color);
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    .core-values {
      grid-template-columns: 1fr;
    }
  }

  /* Animations */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }