/* ============================
   HOW IT WORKS SECTION STYLES
============================ */
.how-it-works-section {
  background-color: #ffffff; /* Keep background white */
  min-height: 100vh; /* Flexibility for height */
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  scroll-margin-top: 100px; /* Ensures space when scrolled from header */
  animation: fadeInUp 1.5s ease-out;
}

.container {
  max-width: 900px;
  margin: 0 auto;
}

.steps-container {
  display: flex;
  justify-content: space-around;
  gap: 40px;
  margin-top: 40px;
  flex-wrap: wrap;
}

.step {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  animation: fadeInUp 1.3s ease-in-out;
}

.step-icon {
  background-color: var(--primary-color); /* Secondary green for icons */
  color: #ffffff; /* White icon color */
  font-size: 2.5em;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
}

.step-icon i {
  transition: transform 0.3s ease-out;
}

.step:hover .step-icon {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
  color: var(--secondary-color);
}

.step-content {
  text-align: center;
}

.step h3 {
  font-size: 1.8em;
  margin-bottom: 15px;
  color: var(--primary-color); /* Primary blue for headings */
}

.step p {
  font-size: 1.2em;
  line-height: 1.6;
  color: var(--gray-2); /* Medium gray for description */
}

/* ============================
   RESPONSIVE DESIGN
============================ */
@media (max-width: 768px) {
  .steps-container {
    flex-direction: column;
    gap: 20px;
  }

  .step {
    max-width: 100%;
  }

  .how-it-works-section {
    min-height: auto;
    padding: 60px 20px;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 2em;
  }

  .step h3 {
    font-size: 1.5em;
  }

  .step p {
    font-size: 1.1em;
  }

  .steps-container {
    gap: 10px;
  }

  .step-icon {
    width: 100px;
    height: 100px;
    font-size: 2em;
  }
}

/* ============================
   ANIMATIONS
============================ */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}