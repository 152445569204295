/* ============================
   CONTACT SECTION STYLES
============================ */
.contact-section {
  background-color: var(--neutral-light); /* Use neutral light background */
  padding: 80px 20px;
  text-align: center;
  scroll-margin-top: 100px;
  min-height: calc(100vh - 200px); /* Ensures section doesn't push footer */
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: fadeInUp 1.5s ease-out;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.contact-content {
  display: flex;
  justify-content: space-between; /* Keep form and contact info aligned */
  gap: 40px;
  flex-wrap: wrap;
}

.contact-form {
  flex: 2;
  max-width: 700px;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}

.contact-info {
  flex: 1;
  max-width: 500px;
  text-align: left;
}

.form-input {
  width: 100%;
  padding: 20px; /* Prominent padding for inputs */
  margin-bottom: 20px;
  border: 1px solid var(--gray-1); /* Light gray border for inputs */
  border-radius: 5px;
  font-size: 1.2em; /* Larger font for better readability */
  background-color: #ffffff; /* White background for input */
}

textarea {
  resize: vertical;
  min-height: 200px; /* Larger height for textarea */
  padding: 20px;
}

.contact-info p {
  font-size: 1.2em;
  margin-bottom: 15px;
  color: var(--gray-2); /* Medium gray for contact info text */
}

.btn {
  display: inline-block;
  padding: 12px 24px;
  background-color: var(--primary-color); /* Primary button color */
  color: #FFFFFF;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.2em;
}

.btn:hover {
  background-color: var(--secondary-color); /* Use secondary color for hover */
}

/* Form Feedback */
.form-status {
  margin-top: 10px;
  font-size: 1.2em;
  color: var(--secondary-color); /* Secondary color for feedback text */
}

/* ============================
   RESPONSIVE DESIGN
============================ */
@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
    align-items: center;
  }

  .contact-form, .contact-info {
    max-width: 100%;
    text-align: center;
  }

  .form-input {
    font-size: 1.1em; /* Slightly smaller font on mobile */
  }

  textarea {
    min-height: 150px; /* Reduce textarea height on mobile */
  }
}

/* ============================
   ANIMATIONS
============================ */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}