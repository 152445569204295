/* Global styles for the body element */
body {
  margin: 0; /* Remove default margin from body */
  
  /* Font family settings for cross-platform compatibility */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  
  /* Optimize font rendering for webkit and Mozilla systems */
  -webkit-font-smoothing: antialiased; /* Smoother font rendering on WebKit browsers */
  -moz-osx-font-smoothing: grayscale;  /* Smoother font rendering on macOS browsers */
}

/* Styles for code elements to use monospaced fonts */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}