/* ============================
   FOOTER STYLES
============================ */
.footer {
  background-color: var(--secondary-color); /* Use secondary color (dark blue) */
  color: #ffffff;
  padding: 40px 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column; /* For vertical stacking */
  justify-content: center;
  align-items: center;
}

.footer-text {
  margin-bottom: 10px;
  font-size: 1.2em;
}

.footer-links {
  display: flex;
  gap: 20px; /* Add spacing between links */
  margin-bottom: 10px;
}

.footer-link {
  color: #ffffff; /* Ensure the text color is white */
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.footer-link:hover {
  color: var(--secondary-color); /* Primary color (orange) on hover */
  text-decoration: underline;
}

/* Social Media Section */
.social-media {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon {
  width: 30px;
  margin: 0 10px;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.2); /* Scale on hover */
  filter: brightness(1.2); /* Brighten on hover */
}

/* ============================
   RESPONSIVE DESIGN
============================ */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-links {
    margin-bottom: 15px;
  }

  .social-media {
    margin-top: 10px;
  }
}