/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Dark overlay background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white; /* Keep background white for contrast */
  padding: var(--padding-small); /* Use global padding */
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  color: var(--gray-3); /* Use dark gray text from global variables */
  box-shadow: var(--shadow-medium); /* Use medium shadow for depth */
}

.modal-body {
  max-height: 400px;
  overflow-y: auto;
  color: var(--gray-3); /* Ensure text inside modal is dark gray */
  line-height: 1.6; /* Use consistent line-height for readability */
}

.modal-close-btn {
  margin-top: 10px;
  background-color: var(--primary-color); /* Use primary color for the close button */
  border: none;
  padding: 10px 20px;
  color: var(--btn-text-color); /* White text on buttons, consistent with your button styles */
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
  box-shadow: var(--shadow-light); /* Light shadow for a modern effect */
}

.modal-close-btn:hover {
  background-color: var(--secondary-color); /* Use secondary color for hover effect */
  box-shadow: var(--shadow-hover); /* Use hover shadow for depth on hover */
  transform: translateY(-3px); /* Slight lift effect on hover */
}

/* Responsive Modal Adjustments */
@media (max-width: 768px) {
  .modal-content {
    width: 95%; /* Slightly wider for mobile screens */
    padding: var(--padding-small); /* Adjust padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .modal-content {
    max-width: 100%;
    padding: var(--padding-small); /* Consistent padding for mobile */
  }

  .modal-body {
    max-height: 300px; /* Reduce modal body height on small screens */
  }
}