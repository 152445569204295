/* ============================
   FEATURES SECTION STYLES
============================ */
.features-section {
  background-color: var(--neutral-light); /* Light neutral background */
  min-height: 100vh; /* Flexible height */
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  scroll-margin-top: 100px; /* Smooth scroll margin */
  animation: fadeInUp 1.5s ease-out;
}

.features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  padding-bottom: 30px;
}

.feature {
  background-color: #ffffff; /* White background for feature cards */
  border: 1px solid var(--secondary); 
  border-radius: 10px;
  padding: 30px;
  width: 300px;
  max-width: 100%;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-out;
  animation: fadeInUp 1.3s ease-in-out;
}

.feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow on hover */
}

.feature h3 {
  font-size: 1.8em;
  margin-bottom: 15px;
  color: var(--primary-color); /* Primary blue for headings */
}

.feature p {
  font-size: 1.2em;
  line-height: 1.6;
  color: var(--gray-2); /* Medium gray for feature description text */
}

/* ============================
   RESPONSIVE DESIGN
============================ */
@media (max-width: 768px) {
  .features-container {
    gap: 20px;
  }

  .feature {
    width: 100%;
    padding: 20px;
  }

  .features-section {
    min-height: auto; /* Flexible height for smaller screens */
    padding: 60px 20px;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 2em;
    margin-bottom: 30px;
  }

  .feature h3 {
    font-size: 1.5em;
  }

  .feature p {
    font-size: 1.1em;
  }

  .features-container {
    gap: 10px;
  }

  .feature {
    padding: 20px;
  }
}

/* ============================
   ANIMATIONS
============================ */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}