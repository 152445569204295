/* ============================
   HERO SECTION STYLES
============================ */
.hero-section {
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: var(--primary-color); /* Use primary blue as background */
  overflow: hidden;
}

/* LEFT COLUMN: HERO IMAGE */
.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 40%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(105, 158, 133, 0)), 
              url('../assets/images/hero-background.jpg') no-repeat center center;
  background-size: cover;
  z-index: 1;
}

/* RIGHT COLUMN: HERO CONTENT */
.hero-content {
  flex: 1;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  color: #ffffff; /* White text */
  max-width: 60%;
  margin-left: auto;
  animation: fadeInUp 1s ease-in-out;
}

.hero-content h1 {
  font-size: 3.5em;
  margin-bottom: 15px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: #ffffff; /* Keep white for contrast */
  animation: fadeInUp 1.2s ease;
}

.sub-heading {
  font-size: 1.5em;
  margin-bottom: 30px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.9); /* Slightly transparent white for contrast */
  text-align: center;
  animation: fadeInUp 1.4s ease;
}

.hero-catchphrase {
  font-size: 1.8em;
  margin-bottom: 30px;
  font-weight: bold;
  color: var(--secondary-color);
  text-align: center;
  animation: fadeInUp 1.6s ease;
}

/* ENHANCED: Coming Soon Styling */
.coming-soon {
  margin: 20px 0;
  padding: 15px 40px;
  background-color: var(--secondary-color);
  border: 2px solid var(--highlight-color); /* Gold border for emphasis */
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.6); /* Soft gold shadow */
  animation: pulse 1.5s infinite;
  cursor: default; /* Disabled button */
}

.coming-soon p {
  font-size: 1.8em;
  font-weight: bold;
  color: var(--highlight-color); /* Gold text */
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0;
}

/* Dynamic Pulsing Effect */
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.6);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.8);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.6);
  }
}

/* ANIMATIONS */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ============================
   RESPONSIVE DESIGN
============================ */
@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
  }

  .hero-image {
    position: relative;
    width: 100%;
    height: 300px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(105, 158, 133, 0)),
                url('../assets/images/hero-background.jpg') no-repeat center center;
    background-size: cover;
    margin-bottom: 20px;
  }

  .hero-content {
    max-width: 100%;
  }

  .hero-content h1 {
    font-size: 2.5em;
  }

  .sub-heading {
    font-size: 1.2em;
  }

  .coming-soon p {
    font-size: 1.6em;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 2em;
  }

  .sub-heading {
    font-size: 1.1em;
  }

  .hero-catchphrase {
    font-size: 1.4em;
  }
}